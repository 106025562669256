<template>
	<div class="flex flex-col h-full w-full">
		<div class="flex flex-row w-full h-full">
			<div class="flex flex-col h-full w-1/3">
				<div class="transition-all duration-500 transform h-full overflow-hidden">
					<div class="bg-gray-50 py-3 px-4 transition-all duration-500 transform w-full h-full overflow-y-auto">
						<div>
							<h2 class="font-semibold text-xl">Activity variables</h2>
							<VariableSelector
								:objects="(({ forms: _, ...variables }) => variables)(activityStore.activityVariables) as ActivityVariables"
								item-key="id"
								:can-create="false"
								variable-group="Activity"
								:selected-variable="selectedVariableAddress"
								:selected-variable-group="selectedVariableGroup"
								:open-groups="openGroups"
								@click="(variableAddress: string, isGroup: boolean) => selectVariable(variableAddress, isGroup, 'Activity')"
							></VariableSelector>
						</div>
						<div>
							<h2 class="border-t border-black pt-1 font-semibold text-xl mt-4">Workflow variables</h2>
							<VariableSelector
								:objects="(({ forms: _, ...variables }) => variables)(workflowProcessStore.variables) as ActivityVariables"
								item-key="id"
								:can-create="false"
								variable-group="Workflow"
								:selected-variable="selectedVariableAddress"
								:selected-variable-group="selectedVariableGroup"
								:open-groups="openGroups"
								@click="(variableAddress: string, isGroup: boolean) => selectVariable(variableAddress, isGroup, 'Workflow')"
							></VariableSelector>
						</div>
						<div v-if="workflowProcessStore.variables.forms">
							<h2 class="border-t border-black pt-1 font-semibold text-xl mt-4">Form submissions</h2>
							<VariableSelector
								v-if="Object.keys(workflowProcessStore.variables.forms.children).length > 0"
								:objects="(({ forms }) => ({ forms }))(workflowProcessStore.variables) as ActivityVariables"
								item-key="id"
								:can-create="false"
								variable-group="Workflow"
								:selected-variable="selectedVariableAddress"
								:selected-variable-group="selectedVariableGroup"
								:open-groups="openGroups"
								@click="(variableAddress: string, isGroup: boolean) => selectVariable(variableAddress, isGroup, 'Workflow')"
							></VariableSelector>
						</div>
					</div>
				</div>
			</div>

			<div class="flex flex-col h-full w-full border-l">
				<div v-if="selectedVariable && !selectedVariable.attributes.group" class="px-4 py-4 transition-all duration-500 transform h-full">
					<div class="bg-white rounded-lg pb-6 sm:p-4 shadow-box-circle transition-all duration-500 transform w-full h-full shadow-lg overflow-hidden">
						<h1 class="text-2xl font-bold">Variable viewer</h1>
						{{ selectedVariableAddress }}
						<VariableViewer v-if="selectedVariable" :key="selectedVariable && selectedVariable.id" :variable="selectedVariable as ActivityVariable" :variable-address="selectedVariableAddress" :variable-group="selectedVariableGroup" />
					</div>
				</div>
				<div v-else-if="selectedVariable && selectedVariable.attributes.group" class="flex flex-row w-full h-full justify-center select-none">
					<div class="transition-all duration-500 transform w-full h-full">
						<div class="rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform w-full flex flex-row justify-center items-center h-full">
							<div class="flex flex-col items-center">
								<h1 class="text-3xl font-bold">Group variable!</h1>
								<h2>Nothing fun to see here!</h2>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="flex flex-row w-full h-full justify-center select-none">
					<div class="transition-all duration-500 transform w-full h-full">
						<div class="rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform w-full flex flex-row justify-center items-center h-full">
							<div class="flex flex-col items-center">
								<h1 class="text-3xl font-bold">Welcome to the variable viewer</h1>
								<FontAwesomeIcon :icon="faWandMagicSparkles" aria-hidden="true" class="mt-8 h-24 w-24 flex-shrink-0 text-primary pointer-events-none text-4xl" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
	import VariableSelector from '../components/VariableSelector.vue';
	import { ref, watch } from 'vue';
	import { get } from 'lodash';
	import VariableViewer from '@modules/activities/components/VariableViewer.vue';
	import { useActivityStore } from '@modules/activities/store';
	import { ActivityVariable, ActivityVariables } from '@/types/activityTemplate';
	import { useWorkflowProcessStore } from '@modules/workflow_process/store';

	const activityStore = useActivityStore();
	const workflowProcessStore = useWorkflowProcessStore();

	const selectedVariableAddress = ref<string>('');
	const selectedVariable = ref<ActivityVariable>();
	const selectedVariableGroup = ref<string>();
	const openGroups = ref<string[]>(['forms']);

	const selectVariable = (variableAddress: string, isGroup: boolean, variableGroup: string) => {
		selectedVariableGroup.value = variableGroup;
		if (isGroup) {
			if (openGroups.value.includes(variableGroup + '.' + variableAddress)) {
				openGroups.value = openGroups.value?.filter((address) => address !== variableGroup + '.' + variableAddress);
			} else {
				openGroups.value.push(variableGroup + '.' + variableAddress);
			}
		}
		selectedVariableAddress.value = variableAddress;
	};

	watch(selectedVariableAddress, async (newVariableAddress) => {
		if (selectedVariableGroup.value == 'Activity') {
			selectedVariable.value = get(activityStore.activityVariables, newVariableAddress);
		} else if (selectedVariableGroup.value == 'Workflow') {
			selectedVariable.value = get(workflowProcessStore.variables, newVariableAddress);
		}
	});
</script>

<style scoped></style>
