<template>
	<div v-if="!variable.attributes.group" class="flex flex-col overflow-y-auto h-full">
		<hr class="my-4" />
		<h2 class="text-lg font-semibold">Variable stash</h2>
		<pre class="whitespace-pre-wrap">{{ variableGroup }}</pre>
		<h2 class="text-lg font-semibold">Variable address</h2>
		<pre class="whitespace-pre-wrap">{{ displayVariableAddress }}</pre>
		<hr class="my-4" />
		<h2 class="text-lg font-semibold">Variable settings</h2>
		<FormKit v-model="data" type="form" :actions="false">
			<div class="grid grid-cols-12 gap-x-2 gap-y-4 px-2">
				<FormKitSchema :schema="variableSchema" />
			</div>
		</FormKit>
		<hr class="my-4" />
		<h2 class="text-lg font-semibold">Current value</h2>
		<pre class="whitespace-pre-wrap">{{ variable.value }}</pre>
		<template v-if="variable.history">
			<hr class="my-4" />
			<h2 class="text-lg font-semibold">Value history</h2>
			<pre class="whitespace-pre-wrap">{{ variable.history }}</pre>
		</template>
		<DebugSection>
			<hr class="my-4" />
			<h2 class="text-lg font-semibold"></h2>
			{{ variable }}
		</DebugSection>
	</div>
	<div v-else class="flex flex-col overflow-y-auto h-full">
		<h2>Folder variable</h2>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, ref } from 'vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { ActivityTemplateVariableType, ActivityTemplateVariableTypeLabel, ActivityVariable } from '@/types/activityTemplate';
	import { CardOption } from '@/types/form';
	import DebugSection from '@components/DebugSection.vue';

	interface Props {
		variable: ActivityVariable;
		variableAddress: string;
		variableGroup: string | undefined;
	}

	const props = defineProps<Props>();

	const data = ref(props.variable);

	const displayVariableAddress = computed(() => {
		let address = props.variableAddress.replaceAll('.children', '');
		address = address.replaceAll('.value', '');
		if (props.variableGroup === 'Activity') {
			return 'activity.' + address;
		}
		return address;
	});

	const variableSchema: FormKitSchemaNode[] = [
		{
			$formkit: 'text',
			name: 'name',
			label: 'Name',
			disabled: true,
			validation: [['safeAlphaNumeric']],
			help: 'What is the name of the variable. (required) (unique)',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-3': true,
				'xl:col-span-3': true,
			},
		},

		{
			$formkit: 'dropdown',
			outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-3': true, 'xl:col-span-3': true },
			validation: [['required']],
			id: 'type',
			disabled: true,
			name: 'type',
			label: 'Type',
			options: {
				if: '$get(list).value',
				then: [
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.STRING],
						value: ActivityTemplateVariableType.STRING,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.NUMBER],
						value: ActivityTemplateVariableType.NUMBER,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATE],
						value: ActivityTemplateVariableType.DATE,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATETIME],
						value: ActivityTemplateVariableType.DATETIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.TIME],
						value: ActivityTemplateVariableType.TIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.CUSTOM],
						value: ActivityTemplateVariableType.CUSTOM,
					},
				] as CardOption[],
				else: [
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.STRING],
						value: ActivityTemplateVariableType.STRING,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.NUMBER],
						value: ActivityTemplateVariableType.NUMBER,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATE],
						value: ActivityTemplateVariableType.DATE,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.DATETIME],
						value: ActivityTemplateVariableType.DATETIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.TIME],
						value: ActivityTemplateVariableType.TIME,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.JSON],
						value: ActivityTemplateVariableType.JSON,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.BOOLEAN],
						value: ActivityTemplateVariableType.BOOLEAN,
					},
					{
						label: ActivityTemplateVariableTypeLabel[ActivityTemplateVariableType.CUSTOM],
						value: ActivityTemplateVariableType.CUSTOM,
					},
				] as CardOption[],
			},
		},
		{
			$formkit: 'group',
			name: 'attributes',
			children: [
				{
					$formkit: 'text',
					name: 'description',
					label: 'Description',
					disabled: true,
					help: 'What is the description for the variable. (optional)',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-6': true,
						'xl:col-span-6': true,
					},
				},
				{
					$formkit: 'toggle',
					id: 'list',
					name: 'list',
					disabled: true,
					label: 'Is this variable a list',
					help: 'List are groups of variables stored together as one',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-3': true,
						'xl:col-span-3': true,
					},
				},
				{
					$formkit: 'toggle',
					id: 'read_only',
					name: 'read_only',
					label: 'Read only',
					disabled: true,
					help: 'Value can only be read and not updated',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-3': true,
						'xl:col-span-3': true,
					},
				},
				{
					$formkit: 'toggle',
					id: 'tracked',
					name: 'tracked',
					disabled: true,
					label: 'Track changes',
					help: 'Track each change made to this variable',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-3': true,
						'xl:col-span-3': true,
					},
				},
				{
					$formkit: 'toggle',
					id: 'hidden',
					name: 'hidden',
					label: 'Hidden',
					disabled: true,
					help: 'Variable exists and can be changed but not seen',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-3': true,
						'xl:col-span-3': true,
					},
				},
			],
		},
	];
</script>

<style scoped></style>
